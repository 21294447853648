<template>
    <Hero></Hero>
    <div class="container mx-auto px-4 py-8">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div v-for="post in blogPosts" :key="post.title" class="bg-white rounded-lg shadow-md overflow-hidden">
                <router-link :to="{ name: 'BlogPost', params: { id: post.id }}" class="block">
                    <img :src="post.thumbnail" :alt="post.title" class="w-full h-48 object-cover">
                    <div class="p-4">
                        <h2 class="text-xl font-bold mb-2 hover:text-blue-600">{{ post.title }}</h2>
                    </div>
                </router-link>
                <div class="p-4 pt-0">
                    <p class="text-gray-600 mb-4">{{ post.content.substring(0, 80) }}...</p>
                    <div class="text-sm text-gray-500">
                        {{ new Date(post.datePublished).toLocaleDateString() }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import Hero from "../components/Hero.vue";
    import { ref, onMounted } from 'vue';

    interface BlogPost {
        id: number;
        title: string;
        content: string;
        thumbnail: string;
        datePublished: Date;
        userId: number;
        price: number;
        projectId: number;
        postTypeId: number;
    }

    const blogPosts = ref<BlogPost[]>([]);

    onMounted(async () => {
        try {
            const response = await fetch('blog/getAllBlogposts');
            if (!response.ok) {
                throw new Error('Failed to fetch blog posts');
            }
            blogPosts.value = await response.json() as BlogPost[];


            blogPosts.value.sort((a, b) => new Date(b.datePublished).getTime() - new Date(a.datePublished).getTime());
        } catch (error) {
            console.error('Error fetching blog posts:', error);
            // You might want to add error handling UI here
        }
    });
 </script>

<style>
.container {
    max-width: 1200px;
}

.grid {
    transition: all 0.3s ease;
}

.bg-white {
    transition: transform 0.2s;
}

.bg-white:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.text-xl {
    color: #2c3e50;
}

.text-gray-600 {
    line-height: 1.6;
}

img {
    transition: opacity 0.3s ease;
}

img:hover {
    opacity: 0.9;
}
</style>