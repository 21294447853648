<template>
    <div class="blogpost-container" v-if="blogpost">
        <h1 class="blogpost-title">{{ blogpost.title }}</h1>
        <div class="thumbnail-container">
            <img :src="blogpost.thumbnail" :alt="blogpost.title" class="blogpost-thumbnail">
        </div>
        <div class="blogpost-metadata">
            <span class="publish-date">Published: {{ formatDate(blogpost.datePublished) }}</span>
        </div>
        <div class="blogpost-content" v-html="blogpost.content"></div>
    </div>
    <div v-else class="loading">
        Loading blog post...
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

interface BlogPost {
    id: number;
    title: string;
    content: string;
    thumbnail: string;
    datePublished: string;
}

export default defineComponent({
    name: 'Blogpost',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const blogpost = ref<BlogPost | null>(null);

        const fetchBlogPost = async () => {
            try {
                const response = await fetch(`/blog/getblogpost/${props.id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                blogpost.value = await response.json();
            } catch (error) {
                console.error('Error fetching blog post:', error);
            }
        };

        const formatDate = (date: string): string => {
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        };

        onMounted(() => {
            fetchBlogPost();
        });

        return {
            blogpost,
            formatDate
        };
    }
});
</script>

<style scoped>
.blogpost-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
}

.blogpost-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    text-align: center;
}

.thumbnail-container {
    margin-bottom: 2rem;
    text-align: center;
}

.blogpost-thumbnail {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.blogpost-metadata {
    margin-bottom: 2rem;
    color: #666;
    font-size: 0.9rem;
}

.blogpost-content {
    line-height: 1.8;
    font-size: 1.1rem;
    color: #333;
}

.blogpost-content p {
    margin-bottom: 1.5rem;
}

.loading {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #666;
}

@media (max-width: 768px) {
    .blogpost-container {
        padding: 1rem;
    }

    .blogpost-title {
        font-size: 2rem;
    }

    .blogpost-content {
        font-size: 1rem;
    }
}
</style>